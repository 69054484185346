import React from "react"
import Layout from "../components/Layout"
import useFetch from "../components/useFetch"
import { Container, Row, Col } from "react-bootstrap"
import * as styles from "../styles/home.module.css"
import Cardcomp from "../components/Cardcomp"
import useSitedata from "../components/useSitedata"
import { Link } from "gatsby"
import Headmeta from "../components/Headmeta"


export default function Index() {
  const artworksPageName = useSitedata("artworksPageName")
  const storeAll = useFetch(artworksPageName)
  // console.dir(storeAll)

  return (
    <Layout>
      <Headmeta importedIn={`${artworksPageName} | Explore our varied artworks which include oil, acrylic, pen, pencil, and mixed media`} importedUrl='heartworks' />
      <div>
        <Container className={styles.homeStyles}>
          <Row className="text-center">
            <Col>
              <h1 className="display-5 pt-5"><span style={{ color: "rgb(195,98,65)" }}>Art</span> <br />because it's immortal</h1>
              <p className='pb-5'>
                Our artworks&mdash;called heartworks in Coordinarts
                jargon&mdash;consist of different mediums and surfaces. You can
                find oil, acrylic, digital, pencil, pen, and mixed media
                heartworks below. Explore them and don't forget to tell us about
                your favourite one. Further, please{" "}
                <Link to="/contact" className="text-primary">contact us</Link> for orders or anything
                else.
              </p>
            </Col>
          </Row>
          <Row className="text-center">
            {storeAll.map(storeSp => (
              <Cardcomp
                cardDataProp={storeSp.frontmatter}
                compAddedIn={artworksPageName}
              />
            ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
